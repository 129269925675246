<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <!-- begin:: Content -->
        <div class="kategori-promo kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <!-- <auto-refresh
                            :table-name="tableData.tableName"
                            :on-refresh="fetch"
                        ></auto-refresh> -->
                            <template
                                v-if="checkPermission('LoyaltyTransactionPointTypeResource.POST.store')">
                                &nbsp;&nbsp;
                                <base-button-modal
                                    :button-text="modalText"
                                    :button-icon="modalIcon"
                                    :button-name="modalName"
                                    :onButtonModalClicked="showModal"
                                ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__body">
                    <crud-table
                        ref="crudTable"
                        :table-options="tableOptions"
                        :repository="repository"
                        :permission="permission"
                        :is-reload-data="isReloadData"
                        @on-click-edit="onClickEdit"
                        @on-click-delete="onClickDelete"
                        @on-click-view="onClickView"
                    ></crud-table>
                </div>
                <form-modal v-if="isShowModal" @event="fetch"></form-modal>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
import LoadSpinner from "./../../../components/_general/LoadSpinner";

import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { mapState, mapMutations, mapActions } from "vuex";
import CrudTable from "./../base/crud-table/crudTable";
const TableRepository = RepositoryFactory.get("transactionPointTypeRepository");


export default {
    components: {
        BaseTitle,
        BaseHeader,
        BaseButtonModal,
        CrudTable,
        FormModal: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(import("./FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            isReloadData:false,
            headTitle: this.$i18n.t("module.transactionPointType"),
            modalText: this.$i18n.t("button.addPointType"),
            modalIcon: "flaticon2-add-1",
            repository: TableRepository,
            permission: {
                view: this.checkPermission('LoyaltyTransactionPointTypeResource.GET.show.Integer.key'),
                edit: this.checkPermission('LoyaltyTransactionPointTypeResource.PUT.update.Integer.key'),
                delete: this.checkPermission('LoyaltyTransactionPointTypeResource.DELETE.destroy.Integer.key'),
                },
            tableOptions: {
                name: "CRUD_TABLE",
                columns: [
                    "id",
                    "type_name",
                    "descriptions",
                    "actions"
                ],
                sortable: [
                    "id",
                    "type_name",
                    "descriptions",
                    ],
                orderBy: { column: 'id', ascending:false },
                headings: {
                    "id":"ID",
                    "type_name":this.$t('common.name'),
                    "descriptions":this.$t('common.description')
                },
                columnsFilter: [
                    { key:"id", type:"number"},
                    { key:"type_name", type:"string"},
                    { key:"descriptions", type:"string"}
                ]
            }
        };
    },

    watch: {
        isShowModal: function(isTrue) {
            if (!isTrue) {
                this.setEditData(null);
                let modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if(!_.isUndefined(modalBackdrop)){
                    modalBackdrop.parentNode.removeChild(modalBackdrop);
                }
            }
        },
        shouldRefreshTable: function(newValue, oldValue) {
            if (newValue === true) {
                this.$refs.crudTable.refreshTable();
            }
        }
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            isLoaded: state => state.isLoaded,
            table: state => state.table,
            isShowModal: state => state.isShowModal,
            showModalMode: state => state.showModalMode,
            shouldRefreshTable: state => state.shouldRefreshTable,
        })
    },
    methods: {
        fetch(){
            this.refreshData(true);
            this.hideModal();
        },
        ...mapActions({
            deleteById: 'Crud/deleteById'
        }),
        ...mapMutations({
            refreshData: 'Crud/REFRESH_TABLE',
            showModal:'Crud/SHOW_MODAL',
            hideModal:'Crud/HIDE_MODAL',
            setRepository: 'Crud/SET_REPOSITORY',
            setModal: 'Crud/SET_MODAL',
            setupTable: 'Crud/SETUP_TABLE',
            setEditData: 'Crud/SET_EDIT_DATA',
            clearData: 'Crud/CLEAR_DATA'
        }),

        checkPermission:function (method) {
            let access = checkPermission.isCanAccess(method);
            return access;
        },
        onClickEdit: function(data) {
            this.$store.commit("Crud/SET_EDIT_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");
        },
        onClickDelete: function(data) {
            this.$store.dispatch("Crud/deleteById", {
                deleteRepository: TableRepository.delete,
                id: data.id,
                name: data.type_name
            });
        },
        onClickView: function(data) {
            this.$store.commit("Crud/SET_VIEW_DATA", data);
            this.$store.commit("Crud/SHOW_MODAL");
        }
    },
    created() {
        this.setRepository(TableRepository);
        this.setModal("modalTransactionPointType");
    },

    mounted() {
        this.hideModal();
    }
};
</script>
